import axios from "axios";
import { isServer } from "../utils";

export const API_BASE_URL = isServer()
  ? process.env.API_BASE_URL_ORIGIN_NIKE_CLOUD
  : process.env.NEXT_PUBLIC_API_URL;

const axiosWithCredentials = axios.create({
  withCredentials: true,
});

export default axiosWithCredentials;

export const makeGetAxiosCallWithCookies = (url: string) =>
  axios.get(url, { withCredentials: true }).then((res) => res.data);
export const makePostAxiosCallWithCookies = (url: string, data: any) =>
  axios
    .post(url, { ...data }, { withCredentials: true })
    .then((res) => res.data);
